/* ---------------------------------- */
/* Dashboard
------------------------------------- */

/* Full Page Adjustments */
.full-page-container {
  height: calc(100vh - 82px);
}

.dashboard-container {
  height: calc(100vh - 82px);
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 1099px) {
  .full-page-container, .dashboard-container {
    height: calc(100vh - 76px);
  }
}

/* Dashboard Basis */

.dashboard-sidebar {
  flex: 0 0 280px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  .dashboard-sidebar-inner {
    overflow: auto;
    height: 100%;
  }
}

.dashboard-content-container {
  flex: 1;
  background-color: #fafafa;
  padding: 0;
  overflow: auto;
  position: relative;
  z-index: 99;
}

.dashboard-content-inner {
  padding: 50px;
  padding-bottom: 0;
  position: relative;
}

/* Dashboard Header Logo Adjustent */
@media (min-width: 1099px) {
  #header-container.dashboard-header {
    .container {
      padding-right: 35px;
      padding-left: 0;
    }
    #logo {
      width: 281px;
      padding-left: 35px;
    }
  }
}

@media (max-width: 1240px) {
  #header-container.dashboard-header #logo {
    width: auto;
  }
}