
/* ---------------------------------- */
/* Google Autocomplete
------------------------------------- */

body {
  .pac-item {
    font-size: 12px;
    color: #999;
    cursor: pointer;
    transition: 0.2s;
    padding: 5px 15px;
  }
  .pac-container {
    border-radius: 0 0 $global-border-radius $global-border-radius;
    border: none;
    box-sizing: content-box;
    margin-top: -2px;
    background-color: #fff;
    border-radius: 0 0 $global-border-radius $global-border-radius;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 100;
  }
}