
/* ---------------------------------- */
/* Counters
------------------------------------- */

.counters-container {
  display: flex;
  flex-wrap: wrap;
}

.single-counter {
  flex: 1;
  text-align: center;
  position: relative;
  border-right: 1px solid #e0e0e0;
  margin-top: 80px;
  &:last-child {
    border-right: 0;
  }
  i {
    font-size: 64px;
    color: #ccc;
    position: absolute;
    top: -20px;
    transform: translateY(-100%);
    left: 0;
    right: 0;
  }
  h3 {
    font-size: 36px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 7px;
  }
  .counter-title {
    color: #888;
    padding-top: 0;
    display: inline-block;
    border-radius: $global-border-radius;
    font-size: 18px;
  }
}

/* Media Queries for Counters */
@media (max-width: 992px) {
  .single-counter {
    flex: 1 1 50%;
    margin-bottom: 30px;
    &:nth-child(2) {
      border: none;
    }
  }
  .counters-container {
    margin-bottom: -30px;
  }
}

/* Media Queries for Counters */
@media (max-width: 768px) {
  .single-counter {
    flex: 1 1 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 30px;
    &:nth-child(2) {
      border-bottom: 1px solid #e0e0e0;
    }
    &:last-child {
      margin-bottom: -15px;
      border-bottom: none;
    }
  }
  .counters-container {
    margin-bottom: -30px;
  }
}