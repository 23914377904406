
/* ---------------------------------- */
/* Bootstrap-select
------------------------------------- */

select {
  &.bs-select-hidden, &.selectpicker {
    display: none !important;
  }
}

.bootstrap-select {
  .btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  > {
    .dropdown-toggle {
      width: 100%;
      padding-right: 25px;
      z-index: 1;
      &.bs-placeholder {
        color: #808080;
        &:hover, &:focus, &:active {
          color: #808080;
        }
      }
    }
    select {
      position: absolute !important;
      bottom: 0;
      left: 50%;
      display: block !important;
      width: 0.5px !important;
      height: 100% !important;
      padding: 0 !important;
      opacity: 0 !important;
      border: none;
      &.mobile-device {
        top: 0;
        left: 0;
        display: block !important;
        width: 100% !important;
        z-index: 2;
      }
    }
  }
}

.has-error .bootstrap-select .dropdown-toggle, .error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}

.bootstrap-select {
  &.fit-width {
    width: auto !important;
  }
  &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }
  &.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
    &:not([class*="col-"]) {
      width: 100%;
    }
    &.input-group-btn {
      z-index: auto;
      &:not(:first-child):not(:last-child) > .btn {
        border-radius: 0;
      }
    }
  }
  &.btn-group {
    &:not(.input-group-btn), &[class*="col-"] {
      float: none;
      display: flex;
      margin-left: 0;
    }
    &.dropdown-menu-right, &[class*="col-"].dropdown-menu-right {
      float: right;
    }
  }
}

.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}

.form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .checkbox .bootstrap-select.btn-group {
  margin-bottom: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control, .checkbox-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}

.checkbox-lg .bootstrap-select.btn-group.form-control .dropdown-toggle, .checkbox-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}

.bootstrap-select.btn-group {
  &.disabled {
    cursor: not-allowed;
    &:focus {
      outline: none !important;
    }
  }
  > .disabled {
    cursor: not-allowed;
  }
  > .disabled:focus {
    outline: none !important;
  }
  &.bs-container {
    position: absolute;
    height: 0 !important;
    padding: 0 !important;
    .dropdown-menu {
      z-index: 1060;
    }
  }
  .dropdown-toggle .filter-option {
    overflow: hidden;
    width: 100%;
    line-height: 48px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    float: left;
    height: 48px;
    display: block;
  }
  .dropdown-menu li.hidden {
    display: none;
  }
}

.language-switcher.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  max-width: 100%;
  float: left;
}

.sort-by .bootstrap-select.btn-group .dropdown-toggle .filter-option {
  max-width: 100%;
  float: left;
  line-height: 20px;
  height: auto;
}

.bootstrap-select {
  &.btn-group .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -2px;
    vertical-align: middle;
    transition: 0.35s;
  }
  &.open .dropdown-toggle .caret {
    transform: rotate(180deg);
  }
  &.btn-group {
    &[class*="col-"] .dropdown-toggle {
      width: 100%;
    }
    .dropdown-menu {
      min-width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: $body-font-size;
      &.inner {
        position: static;
        float: none;
        border: 0;
        padding: 0 5px;
        border-radius: 0;
        box-shadow: none;
      }
      li {
        position: relative;
        margin-bottom: 1px;
        &.disabled {
          opacity: 0.75;
        }
        &.active small {
          color: #fff;
        }
        &.disabled a {
          cursor: not-allowed;
        }
        a {
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          span.text {
            display: inline-block;
          }
        }
        small {
          padding-left: 7px;
          opacity: 0.8;
        }
      }
      .notify {
        position: absolute;
        bottom: 5px;
        width: calc(100% - 20px);
        margin: 0;
        min-height: 26px;
        padding: 3px 5px;
        pointer-events: none;
        padding: 5px 10px;
        background: #333;
        color: #fff;
        margin: 5px;
        margin-bottom: 5px;
        border-radius: $global-border-radius;
        white-space: nowrap;
        font-size: 14px;
        border: none;
      }
    }
    .no-results {
      padding: 5px 10px;
      background: #f6f6f6;
      margin: 5px 0;
      margin-bottom: 0;
      border-radius: $global-border-radius;
      white-space: nowrap;
      font-size: 14px;
    }
    &.fit-width .dropdown-toggle {
      .filter-option {
        position: static;
      }
      .caret {
        position: static;
        top: auto;
        margin-top: -1px;
      }
    }
    &.show-tick .dropdown-menu li a span.text {
      white-space: normal;
    }
  }
  &.show-menu-arrow {
    &.open > .dropdown-toggle {
      z-index: 1061;
    }
    .dropdown-toggle {
      &:before {
        content: '';
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid rgba(204, 204, 204, 0.2);
        position: absolute;
        bottom: -4px;
        left: 9px;
        display: none;
      }
      &:after {
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid white;
        position: absolute;
        bottom: -4px;
        left: 10px;
        display: none;
      }
    }
    &.dropup .dropdown-toggle {
      &:before {
        bottom: auto;
        top: -3px;
        border-top: 7px solid rgba(204, 204, 204, 0.2);
        border-bottom: 0;
      }
      &:after {
        bottom: auto;
        top: -3px;
        border-top: 6px solid white;
        border-bottom: 0;
      }
    }
    &.pull-right .dropdown-toggle {
      &:before {
        right: 12px;
        left: auto;
      }
      &:after {
        right: 13px;
        left: auto;
      }
    }
    &.open > .dropdown-toggle {
      &:before, &:after {
        display: block;
      }
    }
  }
}

.bs-searchbox, .bs-actionsbox, .bs-donebutton {
  padding: 0 5px;
}

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 5px;
  margin-bottom: 10px;
  margin-top: -5px;
  div {
    display: flex;
  }
}

.bootstrap-select.btn-group .bs-actionsbox div button {
  flex: 1;
  background: #f6f6f6;
  box-shadow: none;
  padding: 5px;
  height: 36px;
  font-size: 14px;
  color: #777;
  transition: 0.3s;
  &:first-child {
    border-radius: $global-border-radius 0 0 $global-border-radius;;
    background-color: #f0f0f0;
  }
  &:last-child {
    border-radius: 0 $global-border-radius $global-border-radius 0;
  }
  &:hover {
    background: $primary-color;
    color: #fff;
  }
}

.bs-actionsbox .btn-group button {
  width: 50%;
}

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .btn-group button {
    width: 100%;
  }
}

.bs-searchbox {
  + .bs-actionsbox {
    padding: 0;
  }
  .form-control {
    &:focus {
      margin-bottom: 0;
      width: 100%;
      float: none;
      box-shadow: none;
      background: #f4f4f4;
      margin: -5px 0 10px 0;
      height: 38px;
      line-height: 38px;
      padding: 10px;
    }
    margin-bottom: 0;
    width: 100%;
    float: none;
    box-shadow: none;
    background: #f4f4f4;
    margin: -5px 0 10px 0;
    height: 38px;
    line-height: 38px;
    padding: 10px;
  }
  position: relative;
  &:after {
    font-family: "Material-Icons";
    content: "\e982";
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 0;
    display: inline-block;
    position: absolute;
    margin: 0;
    color: #a0a0a0;
    right: 15px;
    top: 18px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid #aaa;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup, .dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 10px 5px;
  margin: -4px 0 0 0;
  font-size: 15px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  border: none;
  border-radius: $global-border-radius;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  &.pull-right {
    right: 0;
    left: auto;
  }
  .divider {
    display: none;
  }
  > li > a {
    display: block;
    padding: 7px 10px;
    padding-right: 35px;
    clear: both;
    font-weight: normal;
    line-height: 21px;
    color: #808080;
    border-radius: $global-border-radius;
  }
}

.bootstrap-select {
  &:before {
    content: "";
    position: absolute;
    z-index: 2100;
    bottom: 0px;
    left: 0;
    height: 8px;
    width: 100%;
    background-color: #fff;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  &.open:before {
    opacity: 1;
  }
}

.dropdown-menu {
  > {
    li > a:hover {
      color: $primary-color;
      text-decoration: none;
      background-color: rgba($primary-color, 0.07);
    }
    .active > a {
      color: $primary-color;
      text-decoration: none;
      background-color: rgba($primary-color, 0.07);
      &:hover {
        color: $primary-color;
        text-decoration: none;
        background-color: rgba($primary-color, 0.07);
      }
    }
    .disabled > a {
      color: #777;
      &:hover {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
      }
    }
  }
  &.inner {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: #d8d8d8;
      &:hover {
        border-radius: 12px;
        background-color: #c8c8c8;
      }
    }
  }
}

/* Custom Scrollbar for -wekbit */

.bootstrap-select {
  .dropdown-menu {
    opacity: 0;
    transition: 0.4s;
    display: block;
    pointer-events: none;
    visibility: hidden;
    cursor: default;
  }
  &.open .dropdown-menu {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 7px 10px;
  line-height: 20px;
  white-space: nowrap;
  background: #f6f6f6;
  color: #777;
  border-radius: $global-border-radius;
  margin-bottom: 1px;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: 100%;
  bottom: auto;
  margin-bottom: 2px;
}

@media (min-width: 767px) {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }
    .dropdown-menu-left {
      right: auto;
      left: 0;
    }
  }
}

.bootstrap-select.btn-group {
  position: relative;
  button {
    position: relative;
    display: inline-block;
    height: 48px;
    line-height: 0;
    padding: 0 20px;
    margin: 0;
    outline: none;
    font-size: $body-font-size;
    color: #808080;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    font-weight: 500;
    opacity: 1;
    border-radius: $global-border-radius;
    border: none;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.12);
  }
}

/* Select Check Icon */

.hide-tick .check-mark {
  display: none !important;
}

.bootstrap-select {
  &.btn-group .dropdown-menu li {
    a span.check-mark {
      position: absolute;
      display: inline-block;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      line-height: 0;
      width: 20px;
      height: 20px;
      text-align: center;
    }
    span.check-mark:before {
      font-family: "Feather-Icons";
      content: "\e92b";
      font-size: 18px;
      transition: all 0.3s;
      display: inline-block;
      top: 50%;
      right: 0;
      position: absolute;
      margin: 0;
      opacity: 0;
      transform: scale(0.5);
    }
    &.selected span.check-mark:before {
      opacity: 1;
      transform: scale(1);
      transition: all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2), opacity 0.3s;
    }
  }
  .dropdown-menu li.selected {
    a span.check-mark:before, &:hover a span.check-mark:before {
      color: $primary-color;
    }
  }
  .glyphicon:not(.check-mark) {
    font-size: 17px;
    line-height: 0;
    position: relative;
    top: 1px;
    margin-right: 3px;
  }
  .filter-option .glyphicon {
    position: relative;
    top: 1px;
  }
}

/* Icons*/

/* Styles with border */

.with-border {
  .dropdown-menu {
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
  }
  &.bootstrap-select {
    &.btn-group button {
      border: 1px solid #e0e0e0;
      box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.05);
    }
    &:before {
      width: calc(100% - 2px);
      left: 1px;
    }
  }
}