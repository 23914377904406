
/* Single Job
------------------------------------- */

.job-overview {
  border-radius: $global-border-radius;
  background-color: #f9f9f9;
  .job-overview-headline {
    color: $body-heading-color;
    font-size: 20px;
    padding: 20px 35px;
    background-color: #f0f0f0;
    color: $body-heading-color;
    position: relative;
    border-radius: $global-border-radius $global-border-radius 0 0;
  }
  .job-overview-inner {
    padding: 35px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        position: relative;
        display: block;
        font-size: $body-font-size;
        padding-left: 38px;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-weight: 600;
          color: $body-heading-color;
          margin: 0;
          padding: 0;
          display: block;
        }
        h5 {
          font-weight: 500;
          color: $body-font-color;
          font-size: $body-font-size;
          margin: 0;
          padding: 0;
          line-height: 20px;
        }
        i {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 24px;
          color: $primary-color;
        }
      }
    }
  }
}


/* Apply Now Button */
a.apply-now-button, a.course-start-button{
  background-color: $primary-color;
  color: #fff;
  text-align: center;
  font-size: 18px;
  border-radius: $global-border-radius;
  padding: 14px 20px;
  margin-bottom: 35px;
  box-shadow: 0 2px 8px rgba($primary-color, 0.15);
  transition: 0.3s;
  display: block;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 2px 8px rgba($primary-color, 0.35);
  }
  i {
    font-size: 22px;
    position: relative;
    line-height: 0;
    top: 3px;
    margin-left: 5px;
  }
}

@media (max-width: 992px) {
  .is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9999;
    max-width: 90%;
  }
}

/* Single Job Map */

#single-job-map-container {
  position: relative;
  #singleListingMap {
    position: relative;
    height: 340px;
    border-radius: $global-border-radius;
  }
  z-index: 99;
}