
/* ---------------------------------- */
/* Job Listing - Grid Layout
------------------------------------- */

.grid-layout {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
  .job-listing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 30px 30px 0;
    width: calc(100% * (1/2) - 30px);
    flex-direction: column;
  }
  .job-listing-details {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px;
    padding-right: 40px;
  }
  .job-listing-footer {
    flex-grow: 0;
    padding: 20px 30px;
  }
  .job-listing h3.job-listing-title {
    font-size: $listing-heading-font-size;
    line-height: 28px;
  }
  .job-listing-footer .bookmark-icon {
    top: 0;
    right: 25px;
    transform: translateY(-50%) scale(0.96);
  }
  .job-listing .job-listing-company-logo {
    max-width: 56px;
    margin-right: 24px;
    top: 1px;
    flex: 0 0 56px;
  }
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .tasks-list-container.tasks-grid-layout .task-listing .task-listing-details, .grid-layout .job-listing-details, .tasks-list-container.tasks-grid-layout .task-listing-bid {
    flex-basis: 100px;
  }
}
/* IE 11 Fixes - End */

/* Media Queries for Grid Layout */
@media (max-width: 768px) {
  .full-page-content-inner .grid-layout .job-listing {
    margin: 0 0 30px 0;
    width: 100%;
  }
  .grid-layout {
    .job-listing {
      margin: 0 0 30px 0;
      width: 100%;
    }
    width: 100%;
  }
  .full-page-content-inner .grid-layout {
    width: 100%;
  }
  .full-page-content-container .simplebar-content {
    overflow-x: hidden !important;
  }
  .full-page-container.with-map .full-page-content-inner .grid-layout .job-listing {
    width: 100%;
  }
}