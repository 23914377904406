/* ---------------------------------- */
/* Pagination
------------------------------------- */

.pagination {
  margin: 0;
  text-align: center;
}

.pagination-next-prev {
  position: relative;
  top: -66px;
}

.pagination ul {
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    a {
      padding: 10px 0;
      border-bottom: none;
      display: inline-block;
      color: #333;
      background-color: transparent;
      font-weight: 700;
      margin: 0;
      line-height: 22px;
      -webkit-transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -o-transition: all 200ms ease-in-out;
      -ms-transition: all 200ms ease-in-out;
      transition: all 200ms ease-in-out;
      font-size: 14px;
      float: left;
    }
  }
}

.pagination-next-prev ul li a {
  padding: 10px 0;
  border-bottom: none;
  display: inline-block;
  color: #333;
  background-color: transparent;
  font-weight: 700;
  margin: 0;
  line-height: 22px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-size: 14px;
  float: left;
}

.pagination ul li {
  a {
    border-radius: $global-border-radius;
    width: 44px;
    height: 44px;
    padding: 0;
    line-height: 44px;
    i {
      line-height: 44px;
      font-size: 24px;
    }
  }
  &.blank {
    color: #a0a0a0;
    padding: 0 6px;
  }
}

.section.gray .pagination .pagination-arrow a:hover {
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.pagination {
  ul li a {
    &.current-page, &:hover {
      background-color: #333;
      color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
    &.current-page {
      background-color: $primary-color;
      color: #fff;
      box-shadow: 0 2px 8px rgba(102, 103, 107, 0.25);
    }
  }
  .pagination-arrow a {
    background-color: #f0f0f0;
  }
}

.section.gray .pagination .pagination-arrow a {
  background-color: #eaeaea;
}