
/* ---------------------------------- */
/* Category Boxes
------------------------------------- */

.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.category-box {
  flex: 0 0 25%;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  padding: 25px;
  border-radius: $global-border-radius;
  transition: 0.35s;
}

/* IE 11 Fixes */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .category-box {
    max-width: 25%;
    text-align: center;
  }
  .category-box-counter {
    width: 50px !important;
  }
}

/* IE 11 Fixes - End */

/* Box Icon */

.category-box-icon {
  font-size: 42px;
  line-height: 32px;
  color: $primary-color;
  position: relative;
  i {
    height: 42px;
    display: block;
    transition: 0.35s;
    margin-bottom: 12px;
  }
}

/* Box Counter */

.category-box-counter {
  width: auto;
  height: 24px;
  font-size: 14px;
  line-height: 25px;
  border-radius: $global-border-radius;
  padding: 0 8px;
  color: #909090;
  background: rgba(0, 0, 0, 0.06);
  font-weight: 600;
  display: inline-block;
  margin: 0 auto;
  transition: 0.35s;
  margin-bottom: 18px;
}

/* Box Content */

.category-box-content {
  h3 {
    font-size: $body-font-size;
    font-weight: 600;
    color: $body-heading-color;
    margin: 0;
    padding: 0;
    transition: 0.35s;
  }
  p {
    color: #888;
    margin: 0;
    padding: 0;
    line-height: 24px;
    margin-top: 5px;
    transition: 0.35s;
  }
}

/* Hover State */

.category-box:hover {
  background: $primary-color;
  box-shadow: 0 4px 12px rgba($primary-color, 0.2);
  color: #fff;
  .category-box-content h3, .category-box-icon {
    color: #fff;
  }
  .category-box-content p {
    color: rgba(255, 255, 255, 0.7);
  }
  .category-box-counter {
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
}

/* Media Queries for Category Boxes */
@media (max-width: 1240px) {
  .category-box {
    flex: 0 0 33.3%;
  }
}

@media (max-width: 768px) {
  .category-box {
    flex: 0 0 50%;
  }
}

@media (max-width: 480px) {
  .category-box {
    flex: 0 0 100%;
    margin-right: 0;
  }
}