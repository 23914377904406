
/* ---------------------------------- */
/* Radio Button
------------------------------------- */

.radio {
  display: inline-block;
  vertical-align: top;
  label {
    margin: 3px 0;
    cursor: pointer;
    position: relative;
    padding-left: 29px;
    line-height: 25px;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + label .radio-label {
      content: '';
      background: #fff;
      border-radius: 100%;
      border: 2px solid #b4b4b4;
      display: inline-block;
      width: 18px;
      height: 18px;
      position: relative;
      margin-right: 5px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
      background-color: #fff;
      box-shadow: inset 0 0 0 8px #fff;
      z-index: 100;
      position: absolute;
      top: 2px;
      left: 0;
      &:after {
        background-color: $primary-color;
        content: "";
        top: 3px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        opacity: 1;
        transition: 0.3s;
        border-radius: 50%;
        transform: scale(0);
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        z-index: 99;
      }
    }
    &:checked + label .radio-label {
      background-color: $primary-color;
      border-color: $primary-color;
      box-shadow: inset 0 0 0 3px #fff;
    }
  }
}

.radio-label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  display: block;
}

.interactive-effect label .radio-label:before {
  animation: radius-shadow 0.4s;
}

@keyframes radius-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba($primary-color, 0.4);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}


.small-label {
  border-radius: $global-border-radius;
  display: inline-block;
  background: #e0f5d7;
  color: #449626;
  padding: 0 8px;
  line-height: $body-line-height;
  height: 26px;
  margin-left: 5px;
  position: relative;
  top: -1px;
}