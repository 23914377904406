
/* ---------------------------------- */
/* Contact Page
------------------------------------- */

.contact-location-info {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.03);

  .contact-address {
    flex: 1;
  }

  #single-job-map-container {
    flex: 1;
    #singleListingMap {
      border-radius: 0 $global-border-radius $global-border-radius 0;
    }

  }

  .contact-address {
    border: 1px solid #e0e0e0;
    border-right: 0;
    border-radius: $global-border-radius 0 0 $global-border-radius;;
    display: flex;

    > ul {
      align-self: center;
      padding: 40px;
      margin: 0;
      list-style: none;
      font-size: 18px;
      color: #808080;
    }

    ul li {
      padding: 2px 0;
    }

  }
  
  .freelancer-socials {
    margin-top: 10px;
    margin-left: -8px;
    transform: scale(0.9);
  }
}

.contact-address-headline {
  color: $body-heading-color;
  font-weight: 600;
  &:after {
    content: "";
    height: 2px;
    width: 25px;
    background-color: $primary-color;
    position: relative;
    display: block;
    margin: 15px 0;
    border-radius: 3px;
  }
}

@media (max-width: 768px) {
  .contact-location-info {
    flex-direction: column;
    .contact-address {
      border: 1px solid #e0e0e0;
      border-bottom: none;
      border-radius: $global-border-radius $global-border-radius 0 0;
    }
    #single-job-map-container #singleListingMap {
      border-radius: 0 0 $global-border-radius $global-border-radius;
    }
  }
}

#contact div input {
  margin-bottom: 22px;
}