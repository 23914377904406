
/* ---------------------------------- */
/* Checkbox
------------------------------------- */

.checkbox {
  display: inline-block;
  margin-bottom: 0;
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    line-height: 25px;
    span.checkbox-icon {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid #b4b4b4;
      height: 20px;
      width: 20px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      border-radius: $global-border-radius;
      transition: 0.3s;
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
  input {
    + label span.checkbox-icon:after {
      position: absolute;
      top: 2px;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      content: '';
      display: block;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: scale(0.5) rotate(45deg);
      transition: all 0.35s cubic-bezier(0.3, -0.41, 0.19, 2), opacity 0.3s;
    }
    &:checked + label span.checkbox-icon {
      border-color: $primary-color;
      background-color: $primary-color;
      &:after {
        transform: scale(1) rotate(45deg);
        opacity: 1;
      }
    }
  }
}
