
/* ---------------------------------- */
/* Leave Rating Stars
------------------------------------- */

.leave-rating {
  height: 24px;
  float: left;
  position: relative;
  &:hover input[type="radio"]:checked ~ label {
    color: #dadada;
  }
  input[type="radio"] {
    display: inline-block;
    width: 1px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    margin-left: 50%;
    left: 0;
    top: 0;
    transform: translateX(-50%);
    &:checked ~ label {
      color: #ffc600;
    }
  }
  label {
    font-size: 24px;
    float: right;
    letter-spacing: 0px;
    color: #dadada;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      color: #ffc600 !important;
      ~ label {
        color: #ffc600 !important;
      }
    }
  }
}

.leave-rating-title {
  display: block;
  margin: 0 0 4px 0;
}

.welcome-text {
  .leave-rating-container {
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    left: 50%;
    width: 100%;
    display: block;
  }
  .leave-rating {
    position: relative;
    left: 50%;
    transform: translateX(-50%) scale(1.2);
  }
}