
/* ---------------------------------- */
/* Job Listing - Compact List Layout
------------------------------------- */

.compact-list-layout {
  background-color: #fff;
  border-radius: $global-border-radius;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  .job-listing {
    box-shadow: none;
    margin-bottom: 0;
    padding: 30px 35px;
    padding-right: 80px;
    border-radius: 0;
    &:last-of-type {
      border-radius: 0 0 3px 3px;
      overflow: hidden;
    }
    &:first-of-type {
      border-radius: 3px 3px 0 0;
      overflow: hidden;
    }
    h3 {
      font-size: $listing-heading-font-size;
      line-height: 28px;
    }
    &:nth-child(2n) {
      background-color: #fafafa;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      display: block;
      background: $primary-color;
      transition: 0.3s;
      opacity: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      transform: none;
    }
    .job-listing-footer {
      background-color: transparent;
      padding: 0;
      margin-top: 3px;
    }
  }
  .job-listing-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    top: 0;
  }
  .job-listing {
    .job-listing-company-logo {
      max-width: 50px;
      margin-right: 30px;
      top: 0;
    }
    .verified-badge {
      transform: scale(0.85) translate(8px, 8px);
    }
    h3 .verified-badge {
      transform: scale(0.85) translate(0, 0);
      font-weight: 500;
      top: 1px;
      &:before {
        top: -1px;
      }
    }
    span.bookmark-icon {
      top: 50%;
      transform: translateY(-51%);
    }
    &.with-apply-button {
      position: relative;
      padding-right: 35px;
    }
  }
}

/* List Layout Apply Button */
.list-apply-button {
  position: absolute;
  right: 0;
  padding: 10px 20px;
  line-height: 24px;
  position: relative;
  font-size: $body-font-size;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s;
  border-radius: $global-border-radius;
  background: #f0f0f0;
  color: $body-font-color;
  text-align: center;
}

.job-listing.with-apply-button:hover .list-apply-button {
  background-color: $primary-color;
  color: #fff;
  box-shadow: 0 4px 12px rgba($primary-color, 0.15);
}

/* Alt Styles for Single Company Profile */
.boxed-list .compact-list-layout {
  box-shadow: none;
  margin-top: 0;
  .job-listing {
    &:hover {
      border-left: none;
    }
    &:first-of-type {
      border-radius: 0;
    }
  }
}

/* Media Queries for Grid Layout */
@media (max-width: 768px) {
  .list-apply-button {
    flex: auto;
    width: 100%;
    margin-top: 15px;
  }
}