
/* ---------------------------------- */
/* Accordion
------------------------------------- */

.accordion {
  font-size: $body-font-size;
  width: 100%;
  margin: 0 auto;
  border-radius: $global-border-radius;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  .accordion {
    border-radius: 0px;
    box-shadow: none;
  }
}

.accordion-header, .accordion-body {
  background: #fff;
}

.accordion-header {
  padding: 15px 25px;
  background: $primary-color;
  color: #fff;
  cursor: pointer;
  font-size: $body-font-size;
  transition: all .3s;
  position: relative;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15);
}

.accordion .accordion .accordion-header {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.accordion-header:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: "";
  opacity: 0;
  transition: 0.3s;
}

.accordion-body__contents p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/*.accordion__item.active .accordion-header:before,
.accordion-header:hover:before { opacity: 0.04; }*/

.accordion-body {
  background: #fff;
  color: $body-font-color;
  display: none;
}

.accordion-body__contents {
  padding: 25px;
  font-size: $body-font-size;
  line-height: 28px;
}

.accordion__item.active:last-child .accordion-header {
  border-radius: none;
}

.accordion:first-child > .accordion__item > .accordion-header {
  border-bottom: 1px solid transparent;
}

.accordion__item {
  > .accordion-header:after {
    content: "\e957";
    font-family: Material-Icons;
    font-size: 19px;
    position: absolute;
    top: 35%;
    right: 10px;
    transition: .3s all;
    transform: rotate(0deg);
  }
  &.active {
    > .accordion-header:after {
      transform: rotate(-180deg);
    }
    .accordion .accordion-header:before {
      opacity: 0;
    }
  }
  .accordion__item .accordion-header {
    background: #f7f7f7;
    color: $body-font-color;
  }
}