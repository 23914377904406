
/* ---------------------------------- */
/* Tabs
------------------------------------- */

.tabs {
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }
  z-index: 15px;
  position: relative;
  background: #FFFFFF;
  width: 100%;
  border-radius: $global-border-radius;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

.tabs-header {
  position: relative;
  background: $primary-color;
  overflow: hidden;
  .tab-hover {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: auto;
    height: 100%;
    transition: 0.3s ease;
    opacity: 0.08;
    z-index: 90;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% - 100px);
    position: relative;
    z-index: 100;
  }
  li {
    transition: 0.3s ease;
  }
  a {
    z-index: 1;
    display: block;
    box-sizing: border-box;
    padding: 15px 25px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
  }
}

.tabs-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: #fff;
  user-select: none;
  font-size: 19px;
  i {
    cursor: pointer;
    display: inline-block;
    padding: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $global-border-radius;
    text-align: center;
    position: relative;
    z-index: 101;
    margin-left: 5px;
    transition: 0.3s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.tabs-content {
  position: relative;
  transition: 0.3s ease;
  overflow: hidden;
  .tab {
    padding: 25px;
    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    box-shadow: 0 0 20px 10px #FFFFFF;
  }
  .tab {
    display: none;
    &.active {
      display: block;
    }
  }
}

@media (max-width: 992px) {
  .tabs-nav {
    display: none;
  }
  .tabs-header ul {
    flex-direction: column;
    width: 100%;
  }
  .tab-hover {
    display: none;
  }
  .tabs-header {
    a {
      box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.15);
    }
    ul li.active a {
      background-color: rgba(255, 255, 255, 0.07);
    }
  }
}